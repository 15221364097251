<template>
  <div class="teacher">
    <list-template
        :current-page="page"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        hasAdd
        @onAdd="onAdd"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onReset="search = null"
        @onSearch="onSearch"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  components: {ListTemplate},
  data() {
    return {
      loading: true,
      searchConfig: [
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag: 'select',
          options: [],
          value: 'id',
          label: 'school_name',
        },
        {
          prop: 'grade_id',
          placeholder: '筛选年级',
          tag: 'select',
          options: [],
          value: 'id',
          label: 'grade_name',
        }
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'name',
          label: '任务名称'
        },
        {
          prop: 'school',
          label: '应用校区 '
        },
        {
          prop: 'garde',
          label: '应用年级'
        },
        {
          prop: 'creator',
          label: '创建人'
        },
        {
          prop: 'created_at',
          label: '创建时间'
        },
        {
          label: '操作',
          handle: true,
          width: 80,
          render: () => ['查看']
        }
      ],
      search: null,
      total: 0,
      // 表格中的数据
      tableData: [],
    }
  },
  computed: {
    ...mapState(['page'])
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios2.get('/api/work/talk/get-list', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(() => this.loading = false);
    },
    // 新增按钮
    onAdd() {
      this.$router.push('./add')
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    // 表格后面操作被点击
    tableHandle(row) {
      this.$router.push({path: './look', query: {id: row.id}})
    },

  },

  created() {
    this.$_axios2.get('api/sub/substitute/school').then(res => {
      this.searchConfig[0].options = res.data.data
    })
    this.$_axios2.get('api/common/screen/grade-all').then(res => {
      this.searchConfig[1].options = res.data.data
    })
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }
}
</style>
